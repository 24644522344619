<template>
    <div id="body">
        <div class="fatherBox">
            <div class="headerBox">
                <p>上传成绩</p>
            </div>
            <div class="stubody" style="margin:25px 40px 10px 10px;">
                <div style="float: right;padding: 10px;margin-top: -21px;">
                    <el-button type="primary" @click="onSend">发送</el-button>
                </div>
                <div style="text-align: left;width:93%">
                    <template>
                        <div style="width: 80%; margin: auto; margin-left: 80px;">
                            <el-form label-width="120px" class="demo-ruleForm">
                                <el-form-item label="考试名称">
                                    <el-input class="select" v-model.trim="examName" placeholder="清输入考试名称"
                                        style="width: 160px;">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="考试日期">
                                    <el-date-picker style="width: 150px;" v-model="examDate" type="date"
                                        value-format="yyyy-MM-dd" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                                <el-form-item label="考试科目">
                                    <el-input class="select" v-model.trim="examSubject" placeholder="清输入考试科目"
                                        style="width: 160px;">
                                    </el-input>
                                </el-form-item>
                                <el-form-item label="试卷满分">
                                    <el-input class="select" v-model.trim="examScore" placeholder="清输入试卷满分"
                                        style="width: 160px;">
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                    <el-table :data="stu_list" v-loading="loading" style="margin-top: 31px;">
                        <el-table-column type="index" label="序号" width="auto" align="center">
                            <template slot-scope="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="stu_id" label="学号" width="auto" align="center"> </el-table-column>
                        <el-table-column label="科目" width="auto" align="center"><template slot-scope="scope">{{
                                examSubject }}</template></el-table-column>
                        <el-table-column label="试卷满分" width="auto" align="center"> <template slot-scope="scope">{{
                                examScore }}</template></el-table-column>
                        <el-table-column label="学生得分" width="auto" align="center">
                            <template slot-scope="scope"><el-input class="select"
                                    v-model.trim="stu_list[scope.$index].score" placeholder="请输入学生得分"
                                    style="width: 160px;">
                                </el-input></template>
                        </el-table-column>

                        <el-table-column label="考试日期" width="auto" align="center">{{ examDate }} </el-table-column>

                        <el-table-column label="操作" fixed="right" align="center" width="300">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" plain
                                    @click="handleMove(scope.index, scope.row)">删除</el-button>
                                <el-button size="mini" type="primary" plain @click="StuDetail(scope.$index, scope.row)"
                                    style="background: #409eff;color: #fff;">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <el-dialog :visible.sync="dialogVisible" title='导出数据' width="500px">
                <div>
                    <el-form label-width="80px" class="demo-ruleForm">
                        <el-form-item label="时间范围">
                            <el-date-picker v-model="daterange" value-format="yyyy-MM-dd" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doExport">确 定</el-button>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import {
    mapGetters
} from 'vuex'
import Vue from 'vue'
import {
    stuEyeSearch,
    excelDaochueyeDays
} from '@/api/SchoolApi.js'
import {
    sendScore,
    stuData
} from '@/api/TeacherApi.js'
import { Message } from 'element-ui';
export default {
    computed: {
        ...mapGetters(['Status'])
    },
    data() {
        return {
            stuUserID: '',
            StuName: '',
            dstatus: false,
            loading: false,
            cityid: '',
            stu_list: [],
            dialogVisible: false,
            daterange: [],
            examName: '',
            examDate: '',
            examSubject: '',
            examScore: ''
        }
    },
    mounted() {
        this.StuInfo()
    },
    methods: {
        onSend() {
            if (!this.examName) {
                Message.error('请输入考试名称')
                return
            }
            if (!this.examDate) {
                Message.error('请输入考试日期')
                return
            }
            if (!this.examSubject) {
                Message.error('请输入考试科目')
                return
            }

            const studentsList = this.stu_list.filter(item => item.score)

            if (studentsList.length < 1) {
                Message.error('请输入学生成绩')
                return
            }
            const result2 = studentsList.filter(item => Boolean(Number(item.score)) && Number(item.score) < 0)
            if (result2.length) {
                Message.error(`有${result2.length}名学生分数小于0`)
                return
            }
            const list = studentsList.map(item => {
				return {
					StuUserID: item.StuUserID,
					stu_id: item.stu_id,
					obtain_score: item.score
				}
			})
			const score = JSON.stringify(list)

      const examDate = this.examDate.split('-')

            const data = {
                exam_date: examDate[0]+examDate[1]+examDate[2],
                subject: this.examSubject,
                exam_name: this.examName,
                total_score: this.examScore,
                data: score
            }
            sendScore(data).then(res=>{
                Message.success('成绩上传成功')
                this.$router.push({name: 'examList'})
            })
        },
        StuInfo() {
            const data = {}
            stuData(data).then(res => {
                this.stu_list = res.data.map(item => {
                    return {
                        ...item,
                        score: ''
                    }
                })
            }).catch(err => {
                console.log(err)
            })
        },
        upStuHealth() { // 更新健康信息
            this.$router.push({
                name: 'health_update',
                params: {
                    student_id: this.stuUserID                                                                                                                                                                                                                                                                                     ``                                                      `                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       `````````````````````````````````````````````````````````````````````````
                }
            })
        },
        doExport() {
            this.loading = true
            if (this.daterange.length == 0) {
                this.$message.error('请选择时间范围')
                return false
            }
            const data = {
                studentid: this.stuUserID, // 学生ID
                city: this.cityid,
                startdate: this.daterange[0],
                enddate: this.daterange[1]
            }
            excelDaochueyeDays(data).then(res => {
                this.loading = false
                if (res.status.code == 1) {
                    window.open(Vue.prototype.$url + res.data)
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.status.msg)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

}
</script>

<style lang="scss" scoped>
//新样式
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
}

.el-icon-arrow-down {
    font-size: 12px;
}

.stutitle {
    font-size: 30px !important;
    margin-right: 55% !important;
}

.tt {
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: 500;
}

.tabtit {
    width: 100px;
    display: inline-block;
    text-align: right;
}

tr {
    line-height: 45px;
}

#body {
    background: #f0f2f5;
}

.fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
        display: flex;
        height: 52px;
        width: 98%;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid #e9e9e9;

        p {
            font-size: 16px;
            font-family: 萍方-简;
            font-weight: normal;
            color: #000000;
            margin-left: 32px;
        }

        .btn {
            display: flex;
            align-items: center;
            width: 109px;
            height: 32px;
            opacity: 1;
            border-radius: 2px;
        }
    }

    .del {
        float: left,

    }

    .query {
        width: 98%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-left: 32px;

        .queryLeft {
            width: 75%;
            display: flex;
            flex-wrap: wrap;

            div {
                width: 300px;
                line-height: 50px;
                text-align: left;

                .input,
                .select {
                    width: 60%;
                }
            }
        }
    }
}
</style>
